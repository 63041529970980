import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import { Link } from 'gatsby';

import LogoImage from 'common/src/assets/image/saasModern/logo.png';
import { useTranslation } from 'react-i18next';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  pageContext,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FOOTER_WIDGET {
          title
          menuItems {
            text
            url
            staticLink
          }
        }
      }
    }
  `);
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              to={`${pageContext.langPathPrefix}/`}
              logoSrc={LogoImage}
              title="Psono"
              logoStyle={logoStyle}
            />
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.saasModernJson.FOOTER_WIDGET.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={t(widget.title)} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      {item.staticLink ? (
                        <Link
                          to={`${pageContext.langPathPrefix}${item.url}`}
                          className="ListItem"
                        >
                          {t(item.text)}
                        </Link>
                      ) : (
                        <a href={item.url} className="ListItem">
                          {t(item.text)}
                        </a>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row" {...row} style={{ paddingTop: '20px' }}>
          <Box
            {...colOne}
            style={{
              color: 'rgba(52,61,72,0.25)',
              marginTop: '0',
              marginBottom: '0',
            }}
          >
            Copyright &copy; 2020 esaqa GmbH
          </Box>
          <Box
            {...colTwo}
            style={{ color: 'rgba(52,61,72,0.25)', marginBottom: '20px' }}
          >
            <Link
              style={{ color: 'rgba(52,61,72,0.25)', marginLeft: '15px' }}
              to={`${pageContext.langPathPrefix}/legal-notice`}
            >
              {t('Legal Notice')}
            </Link>
            <Link
              style={{ color: 'rgba(52,61,72,0.25)', marginLeft: '15px' }}
              to={`${pageContext.langPathPrefix}/privacy-policy`}
            >
              {t('Privacy Policy')}
            </Link>
            <Link
              style={{ color: 'rgba(52,61,72,0.25)', marginLeft: '15px' }}
              to={`${pageContext.langPathPrefix}/terms-and-conditions`}
            >
              {t('Terms & Conditions')}
            </Link>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, 0],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '130px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
